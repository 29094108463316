import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted, ref } from "vue";
import MobileComponent from "./Phone.vue";
import DesktopComponent from "./Pc.vue";
export default {
  __name: 'App',
  setup(__props) {
    const currentComponent = ref(DesktopComponent);
    onMounted(() => {
      const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      currentComponent.value = mobile ? MobileComponent : DesktopComponent;
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_resolveDynamicComponent(currentComponent.value));
    };
  }
};